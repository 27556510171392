import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { error } from 'protractor';
import { EstudiosService } from '../_services/estudios.service';
import { UrlResolverService } from '../_services/urlResolver.service';
import { UserService } from '../_services/user.service';

export interface UserData {
  nroDocumento: string;
  tipoDocumento: string;
  usuario: string;
  sucursal: string;
}

@Component({
  selector: 'app-visualizar-historial',
  templateUrl: './visualizar-historial.component.html',
  styleUrls: ['./visualizar-historial.component.css'],
})
export class VisualizarHistorialComponent implements OnInit {
  hashDecode: string;
  arrayString: string[];
  userData = {} as UserData;
  estudios: any = [];
  horusRestfullUrl: any;
  hashKey: string;
  hashInvalido: boolean = false;
  hashValido: boolean = false;
  cargandoInfo: boolean = true;
  estudiosImagenes;
  random;
  selectedEstudio;
  selectedEstudios;
  urlEstudioPdf;
  // urlEstudioPdfComp: string;
  esAndroid: boolean;
  // existePDFComp: boolean = true;
  existePDFEstudio: boolean = true;
  userDataExt = {};
  verUserData: boolean = false;
  existeImagen: boolean = false;


  constructor(
    private route: ActivatedRoute,
    private estudiosService: EstudiosService,
    private urlResolver: UrlResolverService,
    private userService: UserService
  ) {
    this.getUrl();
    this.getMobile();
  }

  ngOnInit() {}

  getMobile() {
    if (/android/i.test(navigator.userAgent)) {
      this.esAndroid = true;
    } else {
      this.esAndroid = false;
    }
  }

  getUrl() {
    this.urlResolver.getHorusRestfullUrl().subscribe((respGetUrlHR) => {
      if (respGetUrlHR.ok) {
        this.horusRestfullUrl = respGetUrlHR.mensaje;
        //this.horusRestfullUrl =  'http://localhost/horus_restfull';
        this.getHash(this.horusRestfullUrl);
      } else {
        this.cargandoInfo = false;
        this.hashInvalido = true;
      }
    }, (error) => {
      console.log(error);
      this.cargandoInfo = false;
      this.hashInvalido = true;
    });
  }

  getHash(horusRestfullUrl) {
    this.hashKey = this.route.snapshot.url[1].path;

    this.estudiosService.getHashEstudio(this.hashKey, horusRestfullUrl).subscribe((respGetHash) => {
      //console.log(respGetHash)
      if (respGetHash.ok) {
        this.getUserDataExt(respGetHash.hash);
        this.getHistorialClinicoExterno(respGetHash.hash, horusRestfullUrl);
      } else {
        this.cargandoInfo = false;
        this.hashInvalido = true;
      }
    }, (error) => {
      console.log(error);
      this.cargandoInfo = false;
      this.hashInvalido = true;
    });
  }

  getUserDataExt(hashKey) {
    this.userService.getUserDataExt(hashKey).subscribe((respGetUserDataExt) => {
      if (respGetUserDataExt) {
        this.userDataExt = respGetUserDataExt;
        this.verUserData = true;
      }
    }, (error) => {
      console.log(error);
    });
  }

  getHistorialClinicoExterno(hash, horusRestfullUrl) {
    this.estudiosService.getHistorialClinicoExterno(hash, horusRestfullUrl).toPromise().then((data) => {
      if (data.paginador.elementos.length > 0) {
        this.estudios = data.paginador.elementos.sort((a, b) =>
          <any>new Date(b.fecha).getTime() -
          <any>new Date(a.fecha).getTime()
        );
        this.hashValido = true;
      } else if (data.paginador.elementos.length == 0) {
        this.hashInvalido = true;
      }
      this.cargandoInfo = false;
    }, (error) => {
      console.log(error);
      this.cargandoInfo = false;
      this.hashInvalido = true;
    });
  }

  setSelectedEstudio(estudio) {
    // this.msjeEstad = 'El usuario ' + this.userData.usuario + ' visualizo el estudio numero: ' +estudio.idEpisodio;
    // this.funEstad = 'FX_VisualizarEstudio';

    // this.userService.guardarEstadistica(this.msjeEstad, this.funEstad).subscribe(respGuardarEst => {
    // }, error => {
    //   console.log(error)
    // });

    if (estudio.tipoEpisodio != 'Internacion') {
      
      this.random = Math.floor(Math.random() * 9999999999) + 1000000000;
      
      this.getEstudio(estudio.idEpisodio).subscribe((respGetEst) => {
        if (respGetEst.ok){
          this.selectedEstudio = JSON.parse(respGetEst.mensaje);
          this.selectedEstudios = this.selectedEstudio.estudiosPaciente;
          
          this.selectedEstudios.forEach(element => {
            this.getImgAuroraPublico(element, this.selectedEstudio);
          });

          this.selectedEstudio.idCifrado = this.selectedEstudio.idCifrado.replace(/\//g, '_'); //reemplazo la / de la url 

          this.urlEstudioPdf =
            this.horusRestfullUrl +
            '/api/historiaClinica/pedidos/imprimirparapacientes/pdf/' +
            this.selectedEstudio.idCifrado +
            '/' +
            this.selectedEstudio.idCifrado +
            '/' +
            this.random;

          if (this.esAndroid) {
            this.estudiosService.getEstudioPDF(this.urlEstudioPdf).subscribe(
              (resp) => {
                if (resp == null) {
                  this.existePDFEstudio = false;
                }
              },
              (error) => {
                console.log(error);
              }
            );
          }
        } else {
          this.hashInvalido = true;
        }
      });
    } else {
      this.showEpicrisis(estudio);
    }
  }

  async getImgAuroraPublico(estudio, pedido) {
    console.log(estudio)

    // estudio.pedido = {
    //   numero: pedido.numero
    // }
    const data = {
      hash: this.hashKey,
      numero: pedido.numero,
      idEstudio: estudio.id,
    }
    
    await this.estudiosService.getUrlImgAuroraPublico(data).toPromise().then(resp => {
      // console.log(resp)
      estudio.estadoImgAurora = resp.error;
      estudio.urlAurora = resp.url;
    }, error => {
      console.log(error)
    })
  }

  getEstudio(estudioId) {
    const data = { hash: this.hashKey, nroPedido: estudioId}
    
    return this.estudiosService.getEstudio(data);
  }

  getImagenEstudio(estudio) {
    if (estudio.item) {
      return this.estudiosImagenes[estudio.item - 1] != 0;
    }
  }

  showEpicrisis(estudio) {
    this.random = Math.floor(Math.random() * 9999999999999) + 1000000000000;
    var win = window.open(
      this.horusRestfullUrl +
        '/api/internacion/epicrisis/imprimirporpaciente/pdf/' +
        estudio.idEpisodio +
        '/' +
        this.random,
      '_blank'
    );
    win.focus();
  }

  refreshHistory() {
    this.selectedEstudio = null;
    // this.existePDFComp = true;
    this.existePDFEstudio = true;
  }
}
