import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { AppComponent } from '../app.component';

@Injectable({ providedIn: 'root' })
export class UrlResolverService {
  private baseUrl: string;

  constructor(private http: HttpClient) {
    this.baseUrl = AppComponent.testUrl;
  }

  getHorusRestfullUrl(): Observable<any> {
    // return of({ok:true, mensaje: '/horus_restfull' });
    return this.http.get<any>(this.baseUrl + '/horus-restfull-url');
  }

  getThotRestfullUrl(): Observable<any> {
    return this.http.get<any>(this.baseUrl + '/thot-restfull-url');
  }

  getTiempoWC() {
    return this.http.get<any>(this.baseUrl + '/tiempo-web-checkin');
  }

  getUrlPacientes() {
    return this.http.get<any>(this.baseUrl + '/sitio-pac-url');
  }

  getUrlPago() {
    return this.http.get<any>(this.baseUrl + '/url-pago');
  }

  getUrlWhatsappDxi() {
    return this.http.get<any>(this.baseUrl + "/url-whatsapp-dxi");
  }

  getUrlWhatsappLab() {
    return this.http.get<any>(this.baseUrl + "/url-whatsapp-lab");
  }

  getCampaniaAct(){
    return this.http.get<any>(this.baseUrl + "/url-flag-campania_actualizacion");
  }

  getParamPeticionValoracion() {
    return this.http.get<any>(this.baseUrl + "/param-peticion-valoracion");
  }
  
  getComunicacionUrl() {
    return this.http.get<any>(this.baseUrl + "/comunicacion-url");
  }

}
