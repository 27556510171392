export interface Pais {
  name: string;
  code: string;
  flag: string;
  areaCodeLength?: number;
}

export const PAISES: Pais[] = [
  { name: 'Argentina', code: '+549', flag: 'assets/flags/ar.svg', areaCodeLength: 4 },
  { name: 'Alemania', code: '+49', flag: 'assets/flags/de.svg', areaCodeLength: 2 },
  { name: 'Arabia Saudita', code: '+966', flag: 'assets/flags/sa.svg', areaCodeLength: 2 },
  { name: 'Australia', code: '+61', flag: 'assets/flags/au.svg', areaCodeLength: 1 },
  { name: 'Bangladesh', code: '+880', flag: 'assets/flags/bd.svg', areaCodeLength: 2 },
  { name: 'Bolivia', code: '+591', flag: 'assets/flags/bo.svg', areaCodeLength: 3 },
  { name: 'Brasil', code: '+55', flag: 'assets/flags/br.svg', areaCodeLength: 2 },
  { name: 'Canadá', code: '+1', flag: 'assets/flags/ca.svg', areaCodeLength: 3 },
  { name: 'Chile', code: '+56', flag: 'assets/flags/cl.svg', areaCodeLength: 2 },
  { name: 'China', code: '+86', flag: 'assets/flags/cn.svg', areaCodeLength: 1 },
  { name: 'Colombia', code: '+57', flag: 'assets/flags/co.svg', areaCodeLength: 3 },
  { name: 'Corea del Sur', code: '+82', flag: 'assets/flags/kr.svg', areaCodeLength: 2 },
  { name: 'Costa Rica', code: '+506', flag: 'assets/flags/cr.svg', areaCodeLength: 2 },
  { name: 'Cuba', code: '+53', flag: 'assets/flags/cu.svg', areaCodeLength: 2 },
  { name: 'Ecuador', code: '+593', flag: 'assets/flags/ec.svg', areaCodeLength: 2 },
  { name: 'Egipto', code: '+20', flag: 'assets/flags/eg.svg', areaCodeLength: 2 },
  { name: 'El Salvador', code: '+503', flag: 'assets/flags/sv.svg', areaCodeLength: 2 },
  { name: 'España', code: '+34', flag: 'assets/flags/es.svg', areaCodeLength: 2 },
  { name: 'Estados Unidos', code: '+1', flag: 'assets/flags/us.svg', areaCodeLength: 3 },
  { name: 'Finlandia', code: '+358', flag: 'assets/flags/fi.svg', areaCodeLength: 2 },
  { name: 'Francia', code: '+33', flag: 'assets/flags/fr.svg', areaCodeLength: 1 },
  { name: 'Grecia', code: '+30', flag: 'assets/flags/gr.svg', areaCodeLength: 2 },
  { name: 'Guatemala', code: '+502', flag: 'assets/flags/gt.svg', areaCodeLength: 2 },
  { name: 'Honduras', code: '+504', flag: 'assets/flags/hn.svg', areaCodeLength: 2 },
  { name: 'Hong Kong', code: '+852', flag: 'assets/flags/hk.svg', areaCodeLength: 2 },
  { name: 'India', code: '+91', flag: 'assets/flags/in.svg', areaCodeLength: 2 },
  { name: 'Indonesia', code: '+62', flag: 'assets/flags/id.svg', areaCodeLength: 2 },
  { name: 'Irlanda', code: '+353', flag: 'assets/flags/ie.svg', areaCodeLength: 2 },
  { name: 'Israel', code: '+972', flag: 'assets/flags/il.svg', areaCodeLength: 2 },
  { name: 'Italia', code: '+39', flag: 'assets/flags/it.svg', areaCodeLength: 2 },
  { name: 'Japón', code: '+81', flag: 'assets/flags/jp.svg', areaCodeLength: 1 },
  { name: 'Kenia', code: '+254', flag: 'assets/flags/ke.svg', areaCodeLength: 2 },
  { name: 'Malasia', code: '+60', flag: 'assets/flags/my.svg', areaCodeLength: 2 },
  { name: 'México', code: '+52', flag: 'assets/flags/mx.svg', areaCodeLength: 2 },
  { name: 'Nepal', code: '+977', flag: 'assets/flags/np.svg', areaCodeLength: 2 },
  { name: 'Nicaragua', code: '+505', flag: 'assets/flags/ni.svg', areaCodeLength: 2 },
  { name: 'Noruega', code: '+47', flag: 'assets/flags/no.svg', areaCodeLength: 2 },
  { name: 'Nueva Zelanda', code: '+64', flag: 'assets/flags/nz.svg', areaCodeLength: 2 },
  { name: 'Pakistán', code: '+92', flag: 'assets/flags/pk.svg', areaCodeLength: 2 },
  { name: 'Países Bajos', code: '+31', flag: 'assets/flags/nl.svg', areaCodeLength: 2 },
  { name: 'Panamá', code: '+507', flag: 'assets/flags/pa.svg', areaCodeLength: 2 },
  { name: 'Paraguay', code: '+595', flag: 'assets/flags/py.svg', areaCodeLength: 2 },
  { name: 'Perú', code: '+51', flag: 'assets/flags/pe.svg', areaCodeLength: 2 },
  { name: 'Polonia', code: '+48', flag: 'assets/flags/pl.svg', areaCodeLength: 2 },
  { name: 'Portugal', code: '+351', flag: 'assets/flags/pt.svg', areaCodeLength: 2 },
  { name: 'Puerto Rico', code: '+1', flag: 'assets/flags/pr.svg', areaCodeLength: 3 },
  { name: 'Reino Unido', code: '+44', flag: 'assets/flags/gb.svg', areaCodeLength: 2 },
  { name: 'República Dominicana', code: '+1', flag: 'assets/flags/do.svg', areaCodeLength: 3 },
  { name: 'Rusia', code: '+7', flag: 'assets/flags/ru.svg', areaCodeLength: 2 },
  { name: 'Singapur', code: '+65', flag: 'assets/flags/sg.svg', areaCodeLength: 2 },
  { name: 'Sudáfrica', code: '+27', flag: 'assets/flags/za.svg', areaCodeLength: 2 },
  { name: 'Suecia', code: '+46', flag: 'assets/flags/se.svg', areaCodeLength: 2 },
  { name: 'Suiza', code: '+41', flag: 'assets/flags/ch.svg', areaCodeLength: 2 },
  { name: 'Tailandia', code: '+66', flag: 'assets/flags/th.svg', areaCodeLength: 2 },
  { name: 'Taiwán', code: '+886', flag: 'assets/flags/tw.svg', areaCodeLength: 2 },
  { name: 'Turquía', code: '+90', flag: 'assets/flags/tr.svg', areaCodeLength: 2 },
  { name: 'United States', code: '+1', flag: 'assets/flags/us.svg', areaCodeLength: 3 },
  { name: 'Uruguay', code: '+598', flag: 'assets/flags/uy.svg', areaCodeLength: 2 },
  { name: 'Vietnam', code: '+84', flag: 'assets/flags/vn.svg', areaCodeLength: 2 },
  { name: 'Venezuela', code: '+58', flag: 'assets/flags/ve.svg', areaCodeLength: 3 },
  { name: 'Yemen', code: '+967', flag: 'assets/flags/ye.svg', areaCodeLength: 2 },
  { name: 'Zambia', code: '+260', flag: 'assets/flags/zm.svg', areaCodeLength: 2 },
  { name: 'Zimbabue', code: '+263', flag: 'assets/flags/zw.svg', areaCodeLength: 2 }
];

