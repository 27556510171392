import { Component, OnInit } from '@angular/core';
import { EstudiosService } from '../_services/estudios.service';
import { UrlResolverService } from '../_services/urlResolver.service';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { switchMap } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-visualizar-estudio',
  templateUrl: './visualizar-estudio.component.html',
  styleUrls: ['./visualizar-estudio.component.css'],
})
export class VisualizarEstudioComponent implements OnInit {
  urlActual: string;
  hashKey: string;
  hash: Observable<String>;
  arrayUrl: string[];
  selectedEstudio;
  selectedEstudios;
  private horusRestfullUrl: string;
  random;
  urlEstudioPdf;
  // urlEstudioPdfComp: string;
  estudiosImagenes;
  cargando: boolean;
  hashInvalido: boolean = false;
  selectedId: string;
  esAndroid: boolean;
  // existePDFComp: boolean = false;
  existePDFEstudio: boolean = false;
  existeImagen: boolean;
  displayedColumns : string[] = ['estudio', 'imagen']
  esEstudioVIH: boolean;
  informado: any;
  errorEnHash: boolean;

  constructor(
    private estudiosService: EstudiosService,
    private urlResolver: UrlResolverService,
    private route: ActivatedRoute
  ) {
  }

  async getMobile() {
    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
      this.esAndroid = true;
    } else {
      this.esAndroid = false;
    }
  }

  async ngOnInit() {
    this.cargando = true;
    await this.getMobile();
    await this.getUrlHR();
    this.hash = this.route.paramMap.pipe(
      switchMap((params) => {
        this.selectedId = String(params.get('hash'));
        return this.selectedId;
      })
    );
  }

  async getUrlHR() {
    this.urlResolver.getHorusRestfullUrl().subscribe(
      (respGetUrlHR) => {
        if (respGetUrlHR.ok) {
          this.horusRestfullUrl = respGetUrlHR.mensaje;
          // this.horusRestfullUrl =  'http://localhost/horus_restfull';
          this.getHash();
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }

  async getHash() {
    this.urlActual = window.location.pathname;
    this.arrayUrl = this.urlActual.split('/');
    this.hashKey = this.arrayUrl[2];
    //console.log(this.hashKey)
    await this.estudiosService
      .getHashEstudio(this.hashKey, this.horusRestfullUrl)
      .toPromise().then(
        async (respGetHash) => {
          if (respGetHash.ok) {
            // console.log(respGetHash);
            
            await this.getEstudio(respGetHash.claveEstudios);

          } else {
            this.hashInvalido = true;
          }
        },
        (error) => {
          console.log(error);
          this.hashInvalido = true;
        }
      );
  }

  async getImgAuroraPublico(estudio, pedido) {
    console.log(estudio)

    // estudio.pedido = {
    //   numero: pedido.numero
    // }

    const data = {
      hash: this.hashKey,
      numero: pedido.numero,
      idEstudio: estudio.id,
    }

    await this.estudiosService.getUrlImgAuroraPublico(data).toPromise().then(resp => {
      //console.log(resp)
      estudio.estadoImgAurora = resp.error;
      estudio.urlAurora = resp.url;
    }, error => {
      console.log(error)
    })
  }

  async getEstudio(idEstudio) {
    const data = { hash: this.hashKey, nroPedido: idEstudio}
    
    await this.estudiosService.getEstudio(data).toPromise().then(
      (respGetEst) => {
        // console.log(respGetEst);
        if (respGetEst.ok) {
          respGetEst = JSON.parse(respGetEst.mensaje);
          this.random = Math.floor(Math.random() * 9999999999) + 1000000000;
          this.selectedEstudio = respGetEst;
          this.informado = this.selectedEstudio.estado == 'Informado';
          
          //this.selectedEstudios = respGetEst.estudiosPaciente;

          this.selectedEstudios = respGetEst.estudiosPaciente.sort(
            (a, b) => 0 - (a.numeroItem > b.numeroItem ? -1 : 1)
          );
          if(this.selectedEstudio.estudios.indexOf('HIV') !== -1){
            this.esEstudioVIH = true;
          } //Obtengo los estudios segun el Id del episodio y los ordeno por fecha.

          this.selectedEstudio.estudiosPaciente.forEach(element => {
            this.getImgAuroraPublico(element, this.selectedEstudio);           
          });           
          
          this.selectedEstudio.idCifrado = this.selectedEstudio.idCifrado.replace(/\//g, '_'); //reemplazo la / de la url 
          this.urlEstudioPdf =
            this.horusRestfullUrl +
            '/api/historiaClinica/pedidos/imprimirparapacientes/pdf/' +
            this.selectedEstudio.idCifrado +
            '/' +
            this.selectedEstudio.idCifrado +
            '/' +
            this.random;

          this.hashInvalido = false;
          this.errorEnHash = false;
        } else {
          this.hashInvalido = true;
          
        }
      }, (error) => {
        // console.log(error);
        console.log('Error en getEstudio');
        
        
        // this.hashInvalido = true;
        this.errorEnHash = true;
      }
    );

    

    await this.obtenerPDFs();  
    
    

    this.cargando = false;
  }

  async obtenerPDFs(){
    //Obtengo el pdf del Estudio
    await this.estudiosService.getEstudioPDF(this.urlEstudioPdf).toPromise().then(
      (resp: any) => {
        if (resp.type == 'application/pdf') {
          this.existePDFEstudio = true;
        }
      },
      (error) => {
        console.log(error);
      }
    );
    //Obtengo el pdf del Estudio Complementario
    // await this.estudiosService
    //   .getInfoComplPDF(this.urlEstudioPdfComp)
    //   .toPromise().then(
    //     (respComp: any) => {       
    //        if (respComp.type == 'application/pdf') {
    //          this.existePDFComp = true;
    //        }
    //     },
    //     (error) => {
    //       console.log(error);
    //     }
    //   );
  }
  
  recargarPagina() {
    window.location.reload();
  }

}
